<template>
  <div class="Authority">
    <Header title="意向客户" index="首页" titleOne="合同管理" beforeTitle="意向客户" />
    <div class="content">
      <div class="topUsers">
        <div>
          <el-select v-model="companyParams.province_id" placeholder="请选择省" @change="getcity">
            <el-option v-for="item in province" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
          <el-select v-model="companyParams.city_id" placeholder="请选择市" @change="gettown">
            <el-option v-for="item in city" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
          <el-select v-model="companyParams.town_id" placeholder="请选择区" @change="getstreet">
            <el-option v-for="item in town" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
          <el-select v-model="companyParams.block_id" placeholder="请选择镇/模块">
            <el-option v-for="item in street" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
          <!-- <el-date-picker
				      v-model="companyParamsvalue2"
				      type="daterange"
				      range-separator="至"
				      start-placeholder="开始日期"
				      end-placeholder="结束日期" style="margin-right: 20px;">
          </el-date-picker>-->
          <el-input
            v-model="companyParams.keywords"
            placeholder="请输入企业名称关键字"
            @keyup.enter.native="handleSearch()"
          ></el-input>
          <el-button class="search" @click="handleSearch()">查询</el-button>

          <el-button @click="goExportPotential()" class="import">全部导出</el-button>
        </div>
        <el-button
          v-if="privilige_list.potential.includes('add')"
          class="add"
          @click="handleEdit('')"
        >新增</el-button>
      </div>
      <el-table
        v-loading="loading"
        element-loading-text="正在加载中···"
        :data="company.data"
        style="width: 100%"
      >
        <el-table-column prop="company_name" label="企业名称" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{
            scope.row.company_name ? scope.row.company_name : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="area" label="所属区域" :show-overflow-tooltip="true">
          <template slot-scope="scope">{{ scope.row.area ? scope.row.area : "-" }}</template>
        </el-table-column>
        <el-table-column
          prop="com_type"
          label="企业类型"
          :formatter="operatetype"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column prop="industry_two_name" label="所属行业" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{
            scope.row.industry_two_name ? scope.row.industry_two_name : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="custom_contact" label="客户联系人" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{
            scope.row.custom_contact ? scope.row.custom_contact : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="custom_tel" label="联系方式" :show-overflow-tooltip="true">
          <template slot-scope="scope">{{ scope.row.custom_tel ? scope.row.custom_tel : "-" }}</template>
        </el-table-column>
        <el-table-column prop="follow_up" label="跟进情况" :show-overflow-tooltip="true">
          <template slot-scope="scope">{{ scope.row.follow_up ? scope.row.follow_up : "-" }}</template>
        </el-table-column>
        <el-table-column prop="visit" label="拜访情况" :show-overflow-tooltip="true">
          <template slot-scope="scope">{{ scope.row.visit ? scope.row.visit : "-" }}</template>
        </el-table-column>
        <el-table-column prop="create_time" label="创建时间" :show-overflow-tooltip="true">
          <template slot-scope="scope">{{ scope.row.create_time | fmtdate }}</template>
        </el-table-column>
        <el-table-column prop="true_name" label="创建人" :show-overflow-tooltip="true">
          <template slot-scope="scope">{{ scope.row.true_name ? scope.row.true_name : "-" }}</template>
        </el-table-column>
        <el-table-column prop="is_cooperation" label="合作客户" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <el-switch
              :value="scope.row.is_cooperation"
              :active-value="2"
              :inactive-value="1"
              active-color="#0F38FF"
              inactive-color="#CACDDF"
              :disabled="!privilige_list.potential.includes('change_cooperation')"
              @change="handleChangeStatus($event, scope.row.id)"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="operation" label="操作" width="220px">
          <template slot-scope="scope">
            <el-button
              v-if="privilige_list.potential.includes('edit')"
              class="edit"
              size="mini"
              @click="handleEdit(scope.row.id)"
            >编辑</el-button>
            <el-button
              v-if="privilige_list.potential.includes('view')"
              class="edit"
              size="mini"
              @click="handleDetail(scope.row.id)"
            >详情</el-button>
            <el-button
              v-if="privilige_list.potential.includes('delete')"
              class="del"
              size="mini"
              @click="handleDelete(scope.row.id)"
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="block" v-if="company.total > 0">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="companyParams.page"
          :page-sizes="[2, 10, 20, 40]"
          :page-size="companyParams.limit"
          layout="sizes, prev, pager, next,total,jumper"
          :total="company.total"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
  inject: ["reload"],
  data () {
    return {
      opt: { id: "0", name: "全部" },
      loading: true,
      labelPosition: "top",
      companyParams: {
        page: 1,
        limit: 10,
        is_cooperation: 0,
        keywords: "",
        province_id: "",
        city_id: "",
        town_id: "",
        block_id: "",
        order: "id desc",
      },
      options: [],
      is_cooperation: "",
      province: [],
      city: [],
      town: [],
      street: [],
      privilige_list: {}
    };
  },
  activated () {
    // this.handleSearch();
    this.companyParams
    this.$store.dispatch("getCompany", this.companyParams);
  },
  computed: {
    ...mapState(["company"]),
  },
  created () {
    this.getprovince();
    let arr = localStorage.getItem('privilige')
    this.privilige_list = JSON.parse(arr)
  },
  mounted () {
    this.myname = localStorage.getItem("myname");
  },
  methods: {
    goExportPotential () {
      this.$router.push({
        path: "/data/data_export/go_export",
        query: { item: "potential", total: this.company.total },
      });
    },
    operatetype (row) {
      if (row.com_type == 1) {
        return "有限责任公司";
      } else if (row.com_type == 2) {
        return "股份有限公司";
      } else if (row.com_type == 3) {
        return "国企";
      } else if (row.com_type == 4) {
        return "外商投资企业";
      } else if (row.com_type == 5) {
        return "独资企业";
      } else if (row.com_type == 6) {
        return "个体工商户";
      } else if (row.com_type == 7) {
        return "联营企业";
      } else if (row.com_type == 8) {
        return "集体所有制";
      } else if (row.com_type == 9) {
        return "有限合";
      } else if (row.com_type == 10) {
        return "普通合伙";
      } else if (!row.com_type) {
        return '-'
      }
    },
    handleChangeStatus ($event, id) {
      console.log($event, id);
      if ($event == 2) {
        this.$confirm('确定取消意向客户？将添加到合作客户列表', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.axios.post("/api/company/change_cooperation", { is_cooperation: 1, id: id, }).then((res) => {
            this.$message({
              type: 'success',
              message: res.message
            });
            this.$store.dispatch("getCompany", this.companyParams);
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          });
        });
      }
    },
    handleSizeChange (val) {
      this.companyParams.limit = val;
      this.$store.dispatch("getCompany", this.companyParams);
    },
    handleCurrentChange (val) {
      this.companyParams.page = val;
      this.$store.dispatch("getCompany", { ...this.companyParams });
    },
    handleSearch () {
      this.$store.dispatch("getCompany", { ...this.companyParams, page: '', total: '' });
    },
    handleEdit (id) {
      this.$router.push("/Potential/info?id=" + id);
    },
    getprovince () {
      this.axios.get("/api/region/getProvince").then((res) => {
        this.province = res.data;
        this.province.unshift(this.opt);
        this.$store.dispatch("getCompany", this.companyParams);
      });
    },
    getcity (id) {
      this.axios
        .get("/api/region/getCity", {
          params: {
            province_id: id,
          },
        })
        .then((res) => {
          this.city = res.data;
          this.$store.dispatch("getCompany", this.companyParams);
        });
      if (this.companyParams.province_id == "0") {
        this.companyParams.city_id = "";
        this.companyParams.town_id = "";
        this.companyParams.block_id = "";
      }
    },
    gettown (id) {
      this.axios
        .get("/api/region/getTown", {
          params: {
            city_id: id,
          },
        })
        .then((res) => {
          this.town = res.data;
          this.$store.dispatch("getCompany", this.companyParams);
        });
    },
    getstreet (id) {
      this.axios
        .get("/api/region/getBlock", {
          params: {
            town_id: id,
          },
        })
        .then((res) => {
          this.street = res.data;
          this.$store.dispatch("getCompany", this.companyParams);
        });
    },
    handleInvoicing (index, row) {
      this.$confirm("确定对此项目进行开票申请？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "提交成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消提交",
          });
        });
    },
    // 详情页
    handleDetail (id) {
      this.$router.push({ path: '/potential/detail', query: { id: id } })
    },
    handleDelete (id) {
      this.$confirm("确定删除此项目数据，删除后无法恢复！", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then((res) => {
          this.axios.delete("/api/company/delete", {
            params: {
              id: id,
            },
          });
          this.$message({
            type: "success",
            message: "删除成功!",
          });
          this.reload();
          this.$store.dispatch("getCompany", this.companyParams);
        })
        .catch((res) => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
  beforeUpdate () {
    this.loading = false;
  },
};
</script>

<style scoped="scoped">
.import {
  background: #edeef4;
  color: #909399;
  border: 1px solid #edeef4;
}
.topUsers {
  position: relative;
}
</style>
